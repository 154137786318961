import React from 'react';
import Button from '@mui/material/Button';
import {
  AccordionDetails,
  Box,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import GitHubIcon from '@mui/icons-material/GitHub';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChecklistIcon from '@mui/icons-material/Checklist';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const GithubApp = () => {

  const handleClick = () => {
      window.location.href = `${process.env.REACT_APP_AUTH_URL}/apps/github`;
  };

  return (
    <Card sx={{ minWidth: 275, marginBottom: 2 }}>
      <Accordion expanded={true}>
        <AccordionSummary>
          <Box display="flex" alignItems="center" flexGrow={1}>
            <GitHubIcon />
            <Typography variant="h6" sx={{ fontWeight: 'bold', pl: 1, pr: 1 }} >
              Connect to your Github organisation
            </Typography>
            <Typography variant="h6">

            </Typography>
          </Box>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Connect
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem>
              <ListItemIcon sx={{ color: 'inherit' }}>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Read-only access to your selected repository activity"}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ color: 'inherit' }}>
                <NewspaperIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Repository activity relates to commits, pull requests, issues and comments"}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ color: 'inherit' }}>
                <VerifiedUserIcon />
              </ListItemIcon>
              <ListItemText
                primary={"We do not analyse or review your repository code"}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ color: 'inherit' }}>
                <ChecklistIcon />
              </ListItemIcon>
              <ListItemText
                primary={"You select the repositories you want analysed"}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default GithubApp
