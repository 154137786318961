import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
// import { RepositoryService } from '../../../api/services/Apps/Github/RepositoriesService';

const RepositoryImportPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isImported] = useState(false);

  const handleImportRepositories = async () => {
    setIsLoading(true);
    // try {
    //   await RepositoryService.importRepos();
    //   console.log('Repositories imported successfully');
    //   // Handle success case
    // } catch (error) {
    //   console.error('Error importing repositories:', error);
    //   // Handle error case
    // } finally {
    //   setIsLoading(false);
    // }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Import repositories
      </Typography>
       <ul>
        <li>We only import the repository details, not the code base</li>
        <li>We use this to optimise the analysis your repository&apos;s codebase activity</li>
        <li>You will have the option in the next step and later on to select whicb repositories you want us to analyse</li>
      </ul>
      <Button
            onClick={handleImportRepositories}
            disabled={isLoading || isImported}
            variant="contained"
          >
            {isLoading ? <CircularProgress size={24} /> : 'Import Repositories'}
          </Button>
          {isLoading && (
            <Typography variant="body2" color="textSecondary">
              Importing repositories. Please wait...
            </Typography>
          )}
    </>
  );
};

export default RepositoryImportPage;
