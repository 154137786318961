// import { ErrorResponse } from '../types/error.interface';

// export const isErrorResponse = (data: any): data is ErrorResponse => {
//   return data && typeof data.success === 'boolean' && typeof data.message === 'string';
// };

import { ErrorResponse } from '../types/error.interface';

export const isErrorResponse = (data: unknown): data is ErrorResponse => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'success' in data &&
    typeof (data as ErrorResponse).success === 'boolean' &&
    'message' in data &&
    typeof (data as ErrorResponse).message === 'string'
  );
};
