import React, { useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { setCurrentWorkspaceName } from '../../auth/slices/userSlice';
import { Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useCreateWorkspaceMutation } from './api/workspaceApi';
import { useNavigate } from "react-router-dom";
import { ApiErrorResonse } from '../../../common/types/error.interface';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

export default function WorkspacePage() {
  const navigate = useNavigate();
  const [workspaceName, setWorkspaceName] = useState('');
  const dispatch = useAppDispatch();
  const [createWorkspace] = useCreateWorkspaceMutation();
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await createWorkspace({ name: workspaceName }).unwrap();
      dispatch(setCurrentWorkspaceName(workspaceName));
      navigate('/setup/membership');
    } catch (error: unknown) {
      const typedError = error as ApiErrorResonse;
      if (typedError.data && typedError.data.errors && typedError.data.errors.length > 0) {
        setWarningMessage(typedError.data.errors[0]); // Take the first error message
      }
      // console.error('Failed to create workspace:', typedError.data.errors);
    }
  };

  return (
    <Container sx={{ minWidth: 400 }}>
      <Form onSubmit={handleFormSubmit}>
        {/* <Typography variant="h4" component="h1">
          Create Workspace
        </Typography> */}
        <Typography variant="body1" component="h1" sx={{ mb: 4 }}> {/* Add margin bottom */}
          A Workspace is typically related to your team, organization, or project.
        </Typography>
        <TextField
          label="Workspace Name"
          name="name"
          value={workspaceName}
          onChange={(e) => setWorkspaceName(e.target.value)}
          variant="outlined"
          required
          error={!!warningMessage}  // Set error prop to true if there is an error message
          helperText={warningMessage}  // Display the error message
        />
        <Button type="submit" variant="contained" color="primary">
          Create
        </Button>
      </Form>
    </Container>
  );
}
