import React, { useCallback, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Typography, useTheme } from '@mui/material'
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef, ModuleRegistry
 } from '@ag-grid-community/core';
import { IMembership } from '../../models/membership.interface';
import { useAppSelector } from '../../app/hooks';
import { useGetMembershipsQuery } from './api/peopleApi';
import InviteModal from './components/InviteModal';

ModuleRegistry.registerModules([ ClientSideRowModelModule ]);

const columnDefs: ColDef<IMembership>[] = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 1 },
  { field: 'role', headerName: 'Role', flex: 1 },
  { field: 'last_accessed_at', headerName: 'Last Accessed At', flex: 1 }
];

export default function PeoplePage() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const userSlice = useAppSelector((state) => state.user);
  const gridClassName = isDarkMode ? "ag-theme-quartz-dark" : "ag-theme-quartz";
  const isLoggedIn = userSlice.isLoggedIn;

  const {
    data: memberships,
    isFetching,
    isError,
  } = useGetMembershipsQuery(undefined, { skip: !isLoggedIn });

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = useCallback(() => setOpenModal(true), []);
  const handleClose = useCallback(() => setOpenModal(false), []);

  return (
    <>
      <Box sx={{ flexGrow: 1, paddingBottom: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Typography variant="h3">
              People
            </Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Invite people
            </Button>
          </Grid>
        </Grid>
      </Box>

      <InviteModal open={openModal} onClose={handleClose} workspaceName={userSlice.currentMembership.workspaceName} />

      <Box sx={{ flexGrow: 1 }}>
        {isFetching ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
              <CircularProgress />
            </Box>
          ) : isError ? (
            <Typography color="error">Error loading memberships!</Typography>
          ) : (
            <div
              className={gridClassName}
              style={{ height: 500 }}
            >
              <AgGridReact
                rowData={memberships}
                columnDefs={columnDefs}
              />
            </div>
          )}
      </Box>
    </>
  )
}
