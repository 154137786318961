import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../app/baseQuery';
import { IInvitation } from '../../../models/invitation.interface';
import { isErrorResponse } from '../../../common/utils/apiHelpers';

export const invitationApi = createApi({
  reducerPath: 'invitationApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    createInvitation: builder.mutation<IInvitation, Pick<IInvitation, 'email'>>({
      query: (invitation) => ({
        url: `/invitations`,
        method: 'POST',
        body: {
          invitation: {
            email: invitation.email,
          },
        },
      }),
      transformErrorResponse: (response: FetchBaseQueryError) => {
        if (isErrorResponse(response.data)) {
          return response.data;
        }
        return { success: false, message: 'An unknown error occurred' };
      },
    }),
  }),
});

export const { useCreateInvitationMutation } = invitationApi;
