import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';

export default function ShaCellRenderer(props: ICellRendererParams) {
  const { value, data } = props;

  if (!data) {
    return null;
  }

  return (
    <a href={value} target="_blank" rel="noopener noreferrer">
      {data.sha}
    </a>
  );
}
