import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

// Define the base query with default settings
export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_URL}/api/v1`,
  prepareHeaders: (headers) => {
    const token = Cookies.get('csrf_token');
    if (token) {
      headers.set('X-CSRF-Token', token);
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  credentials: 'include',
});
