import React, { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Divider, FormControl, InputLabel, Select, MenuItem, FormHelperText, Typography, SelectChangeEvent } from '@mui/material';
import { ExcludeGithubUserReason } from '../../../common/constants/excludeGithubUserReason';
import { GithubUserService } from '../../../services/Workspace/Apps/Github/GithubUserService';

interface UserExclusionModalProps {
  isOpen: boolean;
  setOpenStatus: (open: boolean) => void;
}

export default function UserExclusionModal({ isOpen, setOpenStatus } : UserExclusionModalProps) {
  const selectedGithubStat = useAppSelector(state => state.githubStats.selectedGithubStat);
  const [userExclusionReason, setUserExclusionReason] = useState<string>('');

  const titleCase = (str: string) => {
    return str.toLowerCase().split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const handleExcludeUserChange = (event: SelectChangeEvent) => {
    setUserExclusionReason(event.target.value as string);
  };

  const handleFormExcludeUserSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setUserExclusionReason('');
    if (selectedGithubStat) {
      GithubUserService.setExclusionDetails(selectedGithubStat.githubUser.id, userExclusionReason)
        .then((response) => {
          // TODO: display success message
          console.log(response.message);
          setOpenStatus(false);
        })
        .catch((error) => {
          console.error('An error occurred:', error);
        });
    }
  };

  const handleExcludeUserClose = () => {
    setOpenStatus(false);
    setUserExclusionReason('');
  };

  return (
    <Dialog open={isOpen} onClose={handleExcludeUserClose}>
      <DialogTitle>
        Exclude user {' '}
        <Typography component="span" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
            {selectedGithubStat?.githubUser.login}
        </Typography>
        {' '}from all stats
      </DialogTitle>
      <Divider />
      <form onSubmit={handleFormExcludeUserSubmit}>
        <DialogContent>
            <DialogContentText>
                This will remove user{' '}
                <Typography component="span" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                    {selectedGithubStat?.githubUser.login}
                </Typography>
                {' '}from all stats. This can be undone through the Github User Management page.
            </DialogContentText>
            <FormControl fullWidth sx={{marginTop: 2}}>
              <InputLabel id="reason-select-label">Reason  *</InputLabel>
              <Select
                autoFocus
                labelId="reason-select-label"
                id="reason-select"
                value={userExclusionReason !== undefined && userExclusionReason !== null ? userExclusionReason : ''}
                label="Reason *"
                onChange={handleExcludeUserChange}
              >
                {Object.keys(ExcludeGithubUserReason).map((key) => (
                    <MenuItem key={key} value={ExcludeGithubUserReason[key as keyof typeof ExcludeGithubUserReason]}>
                        {titleCase(key)}
                    </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button color="error" onClick={handleExcludeUserClose} >Cancel</Button>
            <Button type="submit" variant="contained" disabled={userExclusionReason === undefined || userExclusionReason === null || userExclusionReason === ''}>
              Save
            </Button>
          </DialogActions>
      </form>
    </Dialog>
  );
}
