import { useTheme } from '@mui/material/styles';

export const useIconButtonStyles = () => {
  const theme = useTheme();

  return {
    borderRadius: '50%',
    padding: 0,
    border: '1px solid',
    ...(theme.palette.mode === 'dark' && {
      color: theme.palette.grey[400],
      borderColor: theme.palette.grey[600],
      backgroundColor: theme.palette.grey[800],
      '&:hover': {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.grey[400],
      },
    }),
    ...(theme.palette.mode === 'light' && {
      color: theme.palette.grey[600],
      borderColor: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        color: theme.palette.grey[200],
        backgroundColor: theme.palette.grey[400],
      },
    }),
  };
};
