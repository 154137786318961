import React from 'react'; // , { useEffect, useState }
// import { useAppSelector } from '../../../../../app/hooks';
// import dayjs from 'dayjs';
// import { ImportAuditsService } from '../../../../../services/Workspace/Apps/Github/ImportAuditsService';
// import { IImportAudit } from '../../../../../models/Apps/importAudit.interface';
import { Button, Divider, FormControl, Stack, Table, TableBody, TableHead, TableRow } from '@mui/material'; // , TableCell, useTheme
import StatTableHeaderCell from '../../../../../common/components/Stats/StatTableHeaderCell';
// import Status from '../../../../../common/components/Stats/Status';
// import { RepositoryAnalysisService } from '../../../../../services/Workspace/Apps/Github/RepositoryAnalysisService';
// import { CustomDatePicker } from '../../../../../common/components/Forms/CustomDatePicker';
import { CustomSelectWithUpgrade } from '../../../../../common/components/Forms/CustomSelectWithUpgrade';

export default function ManageStats() {
  // const theme = useTheme();
  // const [importAudits, setImportAudits] = useState<IImportAudit[]>([]);
  // const [reloadTrigger, setReloadTrigger] = useState(0);

  // useEffect(() => {
  //   ImportAuditsService.get()
  //     .then(function (response) {
  //       setImportAudits(response);
  //     });
  // }, [reloadTrigger]);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // TODO: this should move to AppAuditImport?
    // TODO: refactor based on GithubStatRange
    console.log('TODO: refactor based on GithubStatRange');
    // RepositoryAnalysisService.get(fromDate ?? '', period)
    //   .then(function (response) {
    //     setImportAudits(response);
    //     setReloadTrigger(reloadTrigger + 1);
    //   });
  };

  return (
    <div>
      <h1>Manage Stats</h1>
      <Divider />
      <form onSubmit={handleFormSubmit}>
        <Stack direction="row" spacing={2} sx={{ ml: 1, mt: 4, mb: 4 }}>
          {/* <FormControl style={{ minWidth: 200 }}>
            <CustomDatePicker />
          </FormControl> */}
          <FormControl style={{ minWidth: 200 }}>
            <CustomSelectWithUpgrade />
          </FormControl>
          <Button  type="submit" variant="contained">Import & Analyse</Button>
        </Stack>
      </form>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <StatTableHeaderCell alignment="center" tooltip="Organisation" heading="Organisation" />
            <StatTableHeaderCell alignment="center" tooltip="Analysis Date" heading="Analysis Date" />
            <StatTableHeaderCell alignment="center" tooltip="Status" heading="Status" />
            <StatTableHeaderCell alignment="center" tooltip="Start Time" heading="Start Time" />
            <StatTableHeaderCell alignment="center" tooltip="End Time" heading="End Time" />
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {importAudits ? importAudits.map((importAudit, index) => (
            <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? theme.palette.background.paper : theme.palette.action.hover }}>
              <TableCell align="center">{importAudit.organisation}</TableCell>
              <TableCell align="center">{importAudit.analysisDate}</TableCell>
              <TableCell align="center"><Status status={importAudit.status} /></TableCell>
              <TableCell align="center">{importAudit.startTime}</TableCell>
              <TableCell align="center">{importAudit.endTime}</TableCell>
            </TableRow>
            )) : null} */}
        </TableBody>
      </Table>
    </div>
  )
}
