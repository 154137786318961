import { createTheme } from '@mui/material/styles';

const getTheme = (prefersDarkMode: boolean) => {
  const theme = createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      // Define additional custom colors in the palette if needed
      ...(prefersDarkMode ? {
        // palette values for dark mode
        // primary: grey, // Example: using grey color scale for dark mode

      } : {
        // color assignments for light mode
      }),
    },
    // other theme configurations
  });

  theme.components = {
    ...theme.components, // Preserve existing component customizations
    MuiTableCell: {
      styleOverrides: {
        // Override styles for TableCell here
        root: { // Targets the root element of the TableCell
          backgroundColor: prefersDarkMode ? theme.palette.grey[900] : theme.palette.grey[50],
          color: prefersDarkMode ? theme.palette.grey[200] : theme.palette.text.secondary,

        },
        // Specifically targets cells within TableHead
        head: {
          backgroundColor: prefersDarkMode ? theme.palette.grey[800] : theme.palette.grey[200],
          fontWeight: 'bold',
        },
      },
    },
  };

  return theme;
};

export default getTheme;
