import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../app/baseQuery';
import { ColDef } from '@ag-grid-community/core';
import { IGithubStat, IGithubStatCommitsResponse } from '../../../common/types/githubStatTypes';

interface GetGithubStatsParams {
  githubStatId: string | undefined;
}

interface FilterModel {
  [key: string]: {
    filterType: string;
    type: string;
    filter: string | number | boolean | (string | number | boolean)[];
  };
}

interface GetCommitsByGithubStatParams {
  githubStatId: string | undefined;
  startRow: number;
  endRow: number;
  rowGroupCols?: ColDef[];
  valueCols?: ColDef[];
  groupKeys?: string[];
  sortModel?: { colId: string; sort: 'asc' | 'desc' }[];
  filterModel?: FilterModel;
}

export const githubStatsApi = createApi({
  reducerPath: 'githubStatsApi',
  baseQuery: baseQuery,
  tagTypes: ['GithubStats'],
  endpoints: (builder) => ({
    getGithubStat: builder.query<IGithubStat, GetGithubStatsParams>({
      query: (params) => `github_stats/${params.githubStatId}`,
    }),
    getCommitsByGithubStat: builder.query<IGithubStatCommitsResponse, GetCommitsByGithubStatParams>({
      query: (params) => {
        const sortQuery = params.sortModel?.map(sort => `sort[${sort.colId}]=${sort.sort}`).join('&') || '';
        const filterQuery = params.filterModel
          ? Object.entries(params.filterModel)
              .map(([field, filter]) =>
                `filter[${field}][filterType]=${filter.filterType}&filter[${field}][type]=${filter.type}&filter[${field}][filter]=${filter.filter}`)
              .join('&')
          : '';
        const rowGroupColsQuery = params.rowGroupCols?.map((col, index) => `rowGroupCols[${index}][field]=${col.field}`).join('&') || '';
        const valueColsQuery = params.valueCols?.map((col, index) => `valueCols[${index}][field]=${col.field}`).join('&') || '';
        const groupKeysQuery = params.groupKeys?.map((key, index) => `groupKeys[${index}]=${key}`).join('&') || '';

        const queryString = [
          `startRow=${params.startRow}`,
          `endRow=${params.endRow}`,
          sortQuery,
          filterQuery,
          rowGroupColsQuery,
          valueColsQuery,
          groupKeysQuery,
        ].filter(Boolean).join('&');

        return `github_stats/${params.githubStatId}/commits?${queryString}`;
      }
    }),
  }),
});

export const {
  useGetGithubStatQuery,
  useLazyGetCommitsByGithubStatQuery
} = githubStatsApi;
