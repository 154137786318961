import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../app/baseQuery';
import { IGithubStatRangePeriods, IGithubStatRangeResponse } from '../../../common/types/githubStatTypes';

interface FilterModel {
  [key: string]: {
    filterType: string;
    type: string;
    filter: string | number | boolean | (string | number | boolean)[];
  };
}

interface GetGithubStatRangesParams {
  analysisRange: string | undefined;
  startRow: number;
  endRow: number;
  sortModel?: { colId: string; sort: 'asc' | 'desc' }[];
  filterModel?: FilterModel;
}

export const githubStatRangesApi = createApi({
  reducerPath: 'githubStatRangesApi',
  baseQuery: baseQuery,
  tagTypes: ['GithubStatRanges'],
  endpoints: (builder) => ({
    getByRangeGithubStatRange: builder.query<IGithubStatRangeResponse, GetGithubStatRangesParams>({
      query: (params) => {
        const sortQuery = params.sortModel?.map(sort => `sort[${sort.colId}]=${sort.sort}`).join('&') || '';
        const filterQuery = params.filterModel
          ? Object.entries(params.filterModel)
              .map(([field, filter]) =>
                `filter[${field}][filterType]=${filter.filterType}&filter[${field}][type]=${filter.type}&filter[${field}][filter]=${filter.filter}`)
              .join('&')
          : '';
        return `github_stat_ranges/range/${params.analysisRange}?startRow=${params.startRow}&endRow=${params.endRow}&${sortQuery}&${filterQuery}`;
      }
    }),
    getGithubStatRangeAnalysisRanges: builder.query<IGithubStatRangePeriods[], void>({
      query: () => `github_stat_ranges/analysis_ranges`,
    }),
  }),
});

export const {
  useLazyGetByRangeGithubStatRangeQuery,
  useGetGithubStatRangeAnalysisRangesQuery
} = githubStatRangesApi;
