// TODO: replace all with redux query:
// - src/features/People/peopleApiSlice.ts
// - src/baseQuery.ts
import axios from "axios";
import Cookies from 'js-cookie';

const API_URL = `${process.env.REACT_APP_URL}/api/v1`;

// set withCredentials to true to send cookies
axios.defaults.withCredentials = true;

export const client = axios.create({
  baseURL: API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': Cookies.get('csrf_token')
  }
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
