import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../../../app/hooks';
// import { setCurrentWorkspaceSetupComplete } from '../../../../auth/slices/userSlice';
import { Alert, Box, Button, Checkbox, Chip, Container, LinearProgress, Slide, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { RepositoryService } from '../../../../../services/Workspace/Apps/Github/RepositoriesService';
import { IRepository } from '../../../../../models/Workspace/repository.interface';
// import { MembershipService } from '../../../../../services/MembershipService';
// import Cookies from 'js-cookie';
// import { useNavigate, useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const BULK_ACTIONS_WIDTH = 0.4;
// const POLL_INTERVAL = 15000; // 15 seconds

export default function RepositoriesPage() {
  const userSlice = useAppSelector(state => state.user);
  // const dispatch = useAppDispatch();

  const { id } = useParams();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [tableWidth, setTableWidth] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [repositories, setRepositories] = useState<IRepository[]>([]);
  const [org, setOrg] = useState<string | null>(null);
  const [lastChecked, setLastChecked] = useState<number | null>(null);
  // const navigate = useNavigate();

  // TODO: this no longer works because we:
  //      1. moved from cookies to localStorage
  //      2. moved from Membership setupStatus to Workspace setup review
  //      https://trello.com/c/ZQxDnT5b
  //
  // useEffect(() => {
  //   let interval: NodeJS.Timeout | undefined;

  //   if (userSlice.currentMembership.workspaceSetupComplete === false) {
  //     interval = setInterval(() => {
  //       MembershipService.getSetupStatus()
  //         .then(function () {
  //           // TODO: why don't we return the actual status
  //           dispatch(setCurrentWorkspaceSetupComplete(Cookies.get('membership_setup') ?? ''));
  //         });
  //     }, POLL_INTERVAL);
  //   }

  //   // Cleanup function to clear the interval when the component unmounts
  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, [userSlice.currentMembership.setupStatus]); // Add membershipSetup to the dependency array

  useEffect(() => {
    if (tableContainerRef.current) {
      setTableWidth(tableContainerRef.current.offsetWidth);
    }
  }, [tableContainerRef, repositories]);

  useEffect(() => {
    if (id != null) {
      RepositoryService.get(Number(id))
        .then(function (response) {
          // Handle the response from RepositoryService.get here
          setOrg(response.organisation);
          setRepositories(response.repositories);
        })
        .catch(function (repoError) {
          // Handle any errors from RepositoryService.get here
          console.error(repoError);
        });
    }
  }, [userSlice.currentMembership.workspaceSetupComplete]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    setSelectedRows(event.target.checked ? repositories.map((repo) => repo.id) : []);
  };

  const handleSelectRow = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, repositoryId: number) => {
    let newSelectedRows: number[] = [];

    if (event.shiftKey && lastChecked !== null) {
      const start = repositories.findIndex((repository) => repository.id === repositoryId);
      const end = repositories.findIndex((repository) => repository.id === lastChecked);
      newSelectedRows = repositories.slice(Math.min(start, end), Math.max(start, end) + 1).map((repository) => repository.id);
    } else {
      const selectedIndex = selectedRows.indexOf(repositoryId);
      if (selectedIndex === -1) {
        newSelectedRows = [...selectedRows, repositoryId];
      } else {
        newSelectedRows = selectedRows.filter((name) => name !== repositoryId);
      }
      setLastChecked(repositoryId);
    }

    setSelectedRows(newSelectedRows);
  };

  // TODO:
  // 1. deselect select_all checkbox if we've selected all repos
  // 2. display a status for each repo (e.g. "importing", "analyzing", "analyzed")
  // 3. display nav bar and where should the user go next?
  // TODO: this no longer works because we:
  //      1. moved from cookies to localStorage
  //      2. moved from Membership setupStatus to Workspace setup review
  //      https://trello.com/c/ZQxDnT5b
  const handleImportAndAnalyse = async () => {
    try {
      console.log('TODO: refactor based on GithubStatRange');
      // await RepositoryService.import(Number(id), selectedRows);
      // // TODO: remove this hack
      // // HACK: assume this works to trigger sideNav display
      // setMembershipSetup('complete');
      // navigate('/apps/github/manage_stats');
    } catch (error) {
      // Handle error here, e.g. show an error message
      console.error(error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1">
        Select Repositories from {org} to analyse
      </Typography>
      <Typography variant="body1" component="h1" sx={{ mb: 4 }}>
        {/* An App is a tool you&apos;re currently using with your team. You can
        install an App to your Workspace and we&apos;ll give you the option
        to tell us what you want analysed. */}
        {/* Connect your Apps to your Workspace and then in the next step decide what you want us to analyse */}
      </Typography>
      {
        (userSlice.currentMembership.workspaceSetupComplete === false) ? (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert variant="outlined" severity="info">
              Please wait while we import your repositories. This could take up to a minute for a few hundred repos.
            </Alert>
            <LinearProgress />
          </Stack>
        ) : (
          <div ref={tableContainerRef}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAll}
                      // onChange={(event) => handleRowSelection(rowId, event.target.checked)}
                      indeterminate={selectedRows.length > 0 && selectedRows.length < repositories.length}
                    />
                  </TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Pushed at</TableCell>
                  <TableCell>Fork?</TableCell>
                  <TableCell>Analyse</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {repositories.map((repository) => (
                  <TableRow key={repository.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.includes(repository.id)}
                        onClick={(event) => handleSelectRow(event, repository.id)}
                      />
                    </TableCell>
                    <TableCell>{repository.owner}</TableCell>
                    <TableCell sx={{ maxWidth: 200 }}>{repository.name}</TableCell>
                    <TableCell sx={{ maxWidth: 300 }}>{repository.description}</TableCell>
                    <TableCell>{repository.last_pushed_at}</TableCell>
                    <TableCell>{repository.fork}</TableCell>
                    <TableCell>{repository.analyse}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )
      }
      {/* TODO: can't click on the checkbox the slide is hovering over! */}
      <Slide direction="up" in={selectedRows.length > 0} mountOnEnter unmountOnExit>
        <div style={{
            position: 'fixed',
            bottom: '100px',
            width: tableWidth,
            zIndex: 9999,
        }}>
          <div style={{
            width: BULK_ACTIONS_WIDTH * tableWidth,
            backgroundColor: '#282c34',
            padding: '10px',
            paddingLeft: '20px',
            borderRadius: '10px',
            position: 'relative',
            left: 0,
            right: 0,
            margin: 'auto'
          }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center">
                <Chip
                  label={selectedRows.length}
                  sx={{
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#90caf9',
                    color: '#000',
                    fontWeight: 'bold',
                    marginRight: '10px',
                  }}
                />
                <div>selected</div>
              </Box>
              <Box>
                <Button variant="contained" color="primary" onClick={handleImportAndAnalyse}>Import & Analyse</Button>
              </Box>
            </Box>
          </div>
        </div>
      </Slide>
    </Container>
  )
}
