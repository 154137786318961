import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAppDispatch } from '../../../../app/hooks';
import { IGithubStat } from '../../../../common/types/githubStatTypes';
import { Box, IconButton, Tooltip } from '@mui/material';
import { QueryStats, GitHub } from '@mui/icons-material'; // , PersonRemove

const ActionCellRenderer = ({ data }: { data: IGithubStat }) => {
  // const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  // const handleMenuExcludeUserClick = (githubStat: IGithubStat) => {
  //   setUserModalOpenStatus(true);
  //   handleClose();
  //   dispatch(setSelectedGithubStat(githubStat));
  // };

  const handleMenuGithubStatsClick = (selectedGithubStat: IGithubStat) => {
    const url = `/github_stats/${selectedGithubStat?.id}`;
    window.open(url, '_blank');
  };

  const handleGithubButtonClick = (githubUserLogin: string) => {
    const url = `https://github.com/${githubUserLogin}`;
    window.open(url, '_blank');
  };

  if (!data || !data.id) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
    >
      <Tooltip title="View GitHub User Stats">
        <IconButton
          id={`button-view-${data.id}`}
          onClick={() => handleMenuGithubStatsClick(data)}
          color="primary"
        >
          <QueryStats fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="View GitHub Profile">
        <IconButton
          id={`button-github-${data.githubUser.id}`}
          onClick={() => handleGithubButtonClick(data.githubUser.login)}
          color="primary"
        >
          <GitHub fontSize="small" />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title="Exclude User">
        <IconButton
          id={`button-exclude-${data.githubUser.id}`}
          onClick={() => handleMenuExcludeUserClick(data)}
          color="error"
        >
          <PersonRemove fontSize="small" />
        </IconButton>
      </Tooltip> */}
    </Box>
  );
};

export default ActionCellRenderer;
