import React, { useState } from 'react';
import { Box, Button, Divider, FormControl, MenuItem, Popover, Typography } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { IGithubStatRangePeriods } from '../../../common/types/githubStatTypes';

interface DateRangeSelectorProps {
  buttonText: string;
  analysisPeriods: IGithubStatRangePeriods[] | undefined;
  selectedPeriod: string | null;
  setSelectedPeriod: (period: string | null) => void;
  handleApply: () => void;
}

export default function DateRangeSelector({ buttonText, analysisPeriods, selectedPeriod, setSelectedPeriod, handleApply }: DateRangeSelectorProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleApplyClick = () => {
    handleApply();
    handleClose();
  };

  return (
    <FormControl style={{ minWidth: 300 }}>
      <Button
        id="date-range-button"
        variant="contained"
        startIcon={<DateRangeIcon />}
        aria-controls={open ? 'date-range-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Popover
        id="date-range-popover"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              width: 'auto',
              minWidth: '350px',
            },
          },
        }}
      >
        <Box sx={{ padding: '15px' }}>
          <div style={{ paddingBottom: '10px' }}>
            <Typography variant="button" sx={{ color: 'text.disabled', fontWeight: 'bold' }}>
              Select Date Range
            </Typography>
          </div>
          <Divider />
          {analysisPeriods && analysisPeriods.map((period, index) => (
            <MenuItem
              key={index}
              selected={period.analysisRangeKey === selectedPeriod}
              onClick={() => setSelectedPeriod(period.analysisRangeKey)}
              sx={{ justifyContent: 'space-between', padding: '5px 0' }}
            >
              <Typography>{period.analysisRangeText}</Typography>
              <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                {period.dateRange}
              </Typography>
            </MenuItem>
          ))}
          <Divider />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleApplyClick}>Apply</Button>
          </Box>
        </Box>
      </Popover>
    </FormControl>
  );
}
