import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGithubStat } from '../../../../common/types/githubStatTypes';
import { IGitWeighting } from '../../../../models/Apps/gitWeightings.interface';

interface GithubStatsState {
  stats: IGithubStat[] | null;
  selectedGithubStat: IGithubStat | null;
  clickedStatId: string | null;
}

const initialState: GithubStatsState = {
  stats: [],
  selectedGithubStat: null,
  clickedStatId: null,
};

const githubStatsSlice = createSlice({
  name: 'githubStats',
  initialState,
  reducers: {
    setStats(state, action: PayloadAction<IGithubStat[] | null>) {
      state.stats = action.payload;
    },
    setSelectedGithubStat(state, action: PayloadAction<IGithubStat | null>) {
      state.selectedGithubStat = action.payload;
    },
    setClickedStatId(state, action: PayloadAction<string | null>) {
      state.clickedStatId = action.payload;
    },
    applyCustomWeightings: (state, action: PayloadAction<IGitWeighting>) => {
      if (state.stats) {
        state.stats.forEach(stat => {
          let customTotal = 0;
          Object.keys(action.payload).forEach(key => {
            const customWeighting = action.payload[key as keyof IGitWeighting];
            if (stat.zScores && stat.zwScores && customWeighting !== undefined) {
              const zwScoreCustom = parseFloat((Number(stat.zScores[key as keyof typeof stat.zScores]) * customWeighting).toFixed(3));
              customTotal += zwScoreCustom;
              stat.zwScores[key as keyof typeof stat.zwScores] = zwScoreCustom;
            }
          });
          stat.zwScores.total = customTotal;
        });

        // Sort stats by customTotal in descending order
        state.stats.sort((a, b) => (b.zwScores.total ?? 0) - (a.zwScores.total ?? 0));
      }
    },
  },
});

export const {
  setStats,
  setSelectedGithubStat,
  setClickedStatId,
  applyCustomWeightings
} = githubStatsSlice.actions;

export default githubStatsSlice.reducer;
