// todo: dashboard unclickable due to drawer-expander
// todo: redesign Drawer to incorporate IconButton so it's a smooth transition
//        - current way is a hack
//        - inspo: https://dribbble.com/shots/20964500-Shortcut-Navigation-Refresh
//        - add tooltip to the IconButton to show the drawer is clickable
import * as React from 'react';
import { useIconButtonStyles } from '../hooks/useIconButtonStyles';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import { Box, CssBaseline, Divider, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import GroupsIcon from '@mui/icons-material/Groups';
// import SettingsIcon from '@mui/icons-material/Settings';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import AppsIcon from '@mui/icons-material/Apps';
import EqualizerIcon from '@mui/icons-material/Equalizer';

const maxDrawerWidth = 240;

// Mini variant drawer - START
// https://mui.com/material-ui/react-drawer/#mini-variant-drawer
const openedMixin = (theme: Theme): CSSObject => ({
  width: maxDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: maxDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

// Mini variant drawer - END

export default function SideNav() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const location = useLocation().pathname;
  const [iconButtonWidth, setIconButtonWidth] = useState<number>(0);
  const iconButtonRef = useRef<HTMLButtonElement>(null);
  const [isIconButtonVisible, setIsIconButtonVisible] = React.useState(true);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const iconButtonStyles = useIconButtonStyles();

  // Initial drawer width (when it's closed)
  const initialDrawerWidth = matches ? '65px' : '56px';
  const [drawerWidth, setDrawerWidth] = React.useState(initialDrawerWidth);

  // Resize the Drawer width
  const handleResize = () => {
    const drawer = document.getElementById('drawer');
    if (drawer) {
      const transitionDuration = 225; // The drawer transition duration in milliseconds
      const timeoutId = setTimeout(() => {
        const width = drawer.offsetWidth;
        setDrawerWidth(`${width}px`);
      }, transitionDuration);

      // Return a function to clear the timeout
      return () => {
        clearTimeout(timeoutId);
      };
    }
  };

  // Set the initial drawer width when the component is first rendered
  useEffect(() => {
    setDrawerWidth(initialDrawerWidth);
  }, []);

  // Listen to window resize events and update the drawerWidth state
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const cleanup = handleResize();
    return cleanup;
  }, [open]);

  useLayoutEffect(() => {
    if (iconButtonRef.current) {
      setIconButtonWidth(iconButtonRef.current.offsetWidth);
    }
  }, [iconButtonRef]);

  const handleDrawerOpen = () => {
    setIsIconButtonVisible(false); // set the IconButton to invisible when clicked
    setOpen(true);
    setTimeout(() => {
      setIsIconButtonVisible(true); // reappear the IconButton once the Drawer finishes its transition
    }, theme.transitions.duration.enteringScreen);
  };

  const handleDrawerClose = () => {
    setIsIconButtonVisible(false); // set the IconButton to invisible when clicked
    setOpen(false);
    setTimeout(() => {
      setIsIconButtonVisible(true); // reappear the IconButton once the Drawer finishes its transition
    }, theme.transitions.duration.leavingScreen);
  };

  // funtion to return the react router url
  const getRoute = (): number => {
    // TODO: this should be linked to the /apps route, not the github_stats_range route
    if (location.startsWith('/apps/github')) {
      return 2;
    }

    if (location.startsWith('/github_stats_range')) {
      return 2;
    }

    switch (location) {
      case '/dashboard':
        return 0;
      case '/people':
        return 1;
      case '/apps':
        return 3;
      // case '/settings':
      //   return 4;
      default:
        return 0;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/*
        TODO: write test case for elastic scrolling
        6722b337-c87d-4414-b1f1-faecd44ffd08
       */}
      <Box
        sx={{
          position: 'fixed',
          top: '68px',
          left: `${parseInt(drawerWidth) - iconButtonWidth / 2}px`,
          zIndex: 1000,
          display: isIconButtonVisible ? 'block' : 'none', // hide when the IconButton is not visible
        }}
      >
        <IconButton
          ref={iconButtonRef}
          color="inherit"
          aria-label="toggle drawer"
          onClick={open ? handleDrawerClose : handleDrawerOpen}
          edge="end"
          sx={iconButtonStyles}
        >
          {open ? (
            <ChevronLeftIcon sx={{fontSize: '1.1rem'}}/>
          ) : (
            <ChevronRightIcon sx={{fontSize: '1.1rem'}}/>
          )}
        </IconButton>
      </Box>
      <Drawer
        id="drawer"
        open={open}
        variant="permanent"
        sx={{ zIndex: 999 }}
      >
        <DrawerHeader />
        <List>
          <ListItem key='Dashboard' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={getRoute() === 0}
              component={Link}
              to="/dashboard"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SpeedIcon />
              </ListItemIcon>
              <ListItemText primary='Dashboard' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem key='People' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={getRoute() === 1}
              component={Link}
              to="/people"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary='People' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem key='Github Stats' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={getRoute() === 2}
              component={Link}
              to="/github_stats_range/this_month"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary='Github Stats' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem key='Apps' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={getRoute() === 3}
              component={Link}
              to="/apps"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary='Apps' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        {/* <Divider />
        <List>
          <ListItem key='Settings' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={getRoute() === 4}
              component={Link}
              to="/settings"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Settings' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider /> */}
      </Drawer>
    </Box>
  );
}
