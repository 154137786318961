import React from 'react';
// import { useAppSelector } from '../../../app/hooks'; // , useAppDispatch
import { FormControl, MenuItem, Typography, Button, Menu, Box } from '@mui/material'; // SelectChangeEvent, Tooltip,
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Stack } from '@mui/material';

// const tooltipStyles = {
//   boldText: { fontWeight: 'bold', fontSize: '1.4em' },
//   normalText: { fontSize: '1.2em' }
// };

// const TooltipContent = () => (
//   <>
//     <Typography style={tooltipStyles.boldText}>
//       Stats
//     </Typography>
//     <Typography style={tooltipStyles.normalText}>
//       Available analysis periods are based on your subscription plan. Your current subscription plan is the FREE TRIAL that gives you access to the last 7 days
//     </Typography>
//   </>
// );

export function CustomSelectWithUpgrade() {
  // const analysisPeriod = useAppSelector(state => state.user.currentMembership.analysisPeriod);
  // const dispatch = useAppDispatch();

  // const menuItems = [
  //   { value: 'this_month', label: 'This month' },
  //   { value: 'last_month', label: 'Last month' },
  //   { value: 'this_quarter', label: 'This quarter' },
  //   { value: 'last_quarter', label: 'Last quarter' },
  //   { value: 'this_year', label: 'This year' },
  // ];

  // const allowedMenuItemsMap: Record<string, string[]> = {
  //   'this_month': ['this_month'],
  //   'last_month': ['this_month', 'last_month'],
  //   'this_quarter': ['this_month', 'last_month', 'this_quarter'],
  //   'last_quarter': ['this_month', 'last_month', 'this_quarter', 'last_quarter'],
  //   'this_year': ['this_month', 'last_month', 'this_quarter', 'last_quarter', 'this_year'],
  // };

  // const allowedMenuItems = allowedMenuItemsMap[analysisPeriod] || [];
  // const filteredMenuItems = menuItems.filter(item => allowedMenuItems.includes(item.value));

  // const filteredMenuItems = menuItems.filter(item => {
  //   switch (analysisPeriod) {
  //     case 'this_month':
  //       return item.value === 'this_month';
  //     case 'last_month':
  //       return ['this_month', 'last_month'].includes(item.value);
  //     case 'this_quarter':
  //       return ['this_month', 'last_month', 'this_quarter'].includes(item.value);
  //     case 'last_quarter':
  //       return ['this_month', 'last_month', 'this_quarter', 'last_quarter'].includes(item.value);
  //     case 'this_year':
  //       return ['this_month', 'last_month', 'this_quarter', 'last_quarter', 'this_year'].includes(item.value);
  //     default:
  //       return false;
  //   }
  // });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <FormControl style={{ minWidth: 200 }}>
      <Button
        id="date-range-button"
        variant="contained"
        startIcon={<DateRangeIcon />}
        aria-controls={open ? 'date-range-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Default date range
      </Button>
      <Menu
        id="date-range-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'date-range-menu',
        }}
        sx={{ padding: 2 }}
      >
        <Box sx={{ paddingTop: '5px', paddingLeft: '15px' }}>
          <Typography variant="button" sx={{ color: 'text.disabled', fontWeight: 'bold' }}>Date range</Typography>
        </Box>
        <MenuItem onClick={handleClose}>
          <Stack direction="row" spacing={2} justifyContent="space-between" >
            <Typography>This month</Typography>
            <Typography variant="body2" sx={{ color: 'text.disabled' }}>dd mmm - dd mmm, yyyy</Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Stack direction="row" spacing={2}>
            <Typography>This month</Typography>
            <Typography variant="body2" sx={{ color: 'text.disabled' }}>dd mmm - dd mmm, yyyy</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </FormControl>
  );
}
