import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import store from './app/store';
// import Cookies from 'js-cookie';
// import { setUserData } from './common/slices/userSlice';  // Import the action creator
import "./index.css";
import App from "./app/App";
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from "./reportWebVitals";
// import * as Sentry from '@sentry/react';

// used for Sentry
// const isProduction = process.env.NODE_ENV === 'production';
// const isStaging = process.env.NODE_ENV === 'staging'; // Assuming you have a way to determine staging
// const domain = isProduction ? 'prodopshq.com' : isStaging ? 'staging.prodopshq.com' : 'localhost';

// Sentry.init({
//   dsn: process.env.SENTRY_DNS,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   // tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [
//     ...(domain === 'localhost' ? ['localhost', /^localhost(:\d+)?$/] : [`app.${domain}`, `auth.${domain}`, `api.${domain}`]),
//     // "localhost", /^https:\/\/yourserver\.io\/api/
//   ],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

// const userData = {
//   accountName: Cookies.get('user_name') ?? null,
//   accountAvatar: Cookies.get('user_avatar') ?? null,
//   membershipRole: Cookies.get('membership_role') ?? null,
//   membershipId: Cookies.get('membership') ?? null,
//   workspace: Cookies.get('workspace') ?? null,
//   membershipSetup: Cookies.get('membership_setup') ?? null,
//   analysisPeriod: Cookies.get('analysis_period') ?? null,
//   analysisMinDate: Cookies.get('analysis_min_date') ?? null
// };

// store.dispatch(setUserData(userData));  // Use the action creator

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
