import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Typography,
  useTheme
} from '@mui/material';
// import FilterForm from './components/FilterForm';
import UserExclusionModal from './components/UserExclusionModal';
// import { fetchGitWeightings } from '../Workspace/Apps/Github/gitWeightingsSlice';
import {
  useGetGithubStatRangeAnalysisRangesQuery
} from './api/githubStatRangeApi';
import DateRangeSelector from './components/DateRangeSelector';
import { useNavigate, useParams } from 'react-router-dom';
import GithubStatsGrid from './components/grid/GithubStatsGrid';
import { IGithubStatRangeResponse } from '../../common/types/githubStatTypes';

export default function GithubStatsRangePage() {
  const { id } = useParams<{ id: string | undefined }>();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [buttonText, setButtonText] = useState('Default date range');
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(id || 'this_month');
  const [appliedPeriod, setAppliedPeriod] = useState<string | null>(id || 'this_month');

  const [lastCalculated, setLastCalculated] = useState<string | null>(null);
  const [requiresRecalculation, setRequiresRecalculation] = useState<boolean>(false);
  const [totalRowCount, setTotalRowCount] = useState<number | null>(null);

  const analysisRange = appliedPeriod || 'this_month';
  const { data: analysisPeriods } =  useGetGithubStatRangeAnalysisRangesQuery();

  const updateButtonText = (periodKey: string | null, periods: typeof analysisPeriods) => {
    if (periodKey && periods) {
      const selected = periods.find(period => period.analysisRangeKey === periodKey);
      if (selected) {
        setButtonText(`${selected.analysisRangeText} (${selected.dateRange})`);
      }
    }
  };

  useEffect(() => {
    if (analysisPeriods) {
      updateButtonText(selectedPeriod, analysisPeriods);
    }
  }, [analysisPeriods, selectedPeriod, updateButtonText]);

  const handleApply = useCallback(() => {
    setAppliedPeriod(selectedPeriod);
    navigate(`/github_stats_range/${selectedPeriod}`);
  }, [selectedPeriod, navigate]);

  // useEffect(() => {
  //   dispatch(fetchGitWeightings());
  // }, [dispatch]);

  const handleGridDataFetched = useCallback((data: IGithubStatRangeResponse) => {
    setLastCalculated(data?.lastCalculated || null);
    setRequiresRecalculation(data?.requiresRecalculation || false);
    setTotalRowCount(data?.githubStatsTotalRowCount || null);
  }, []);

  return (
    <div>
      <h2>Github Stats</h2>
      <Divider sx={{ mb: 2 }} />


      <DateRangeSelector
        buttonText={buttonText}
        analysisPeriods={analysisPeriods}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        handleApply={handleApply}
      />

      <Box sx={{ mt: 2}}>
        {lastCalculated && (
          <Typography variant="body2">Last Calculated: {lastCalculated}</Typography>
        )}
        {requiresRecalculation && (
          <Typography variant="body2" color="warning.main">Requires Recalculation</Typography>
        )}
        {totalRowCount !== null && (
          <Typography variant="body2">Total Rows: {totalRowCount}</Typography>
        )}
      </Box>

      {/* <FilterForm fromDate={fromDate} period={period} /> */}

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Box sx={{ flexGrow: 1 }}>
        <GithubStatsGrid
          isDarkMode={isDarkMode} analysisRange={analysisRange}
          onGridDataFetched={handleGridDataFetched}
        />
      </Box>
      <UserExclusionModal
        isOpen={isUserModalOpen}
        setOpenStatus={setIsUserModalOpen}
      />
    </div>
  )
}
