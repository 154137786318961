import React from 'react';
import { ICellRendererParams } from "@ag-grid-community/core";
import { IGithubStat } from "../../../../common/types/githubStatTypes";
import ActionCellRenderer from './ActionCellRenderer';

const renderActionCell = (params: ICellRendererParams<IGithubStat>): JSX.Element | null => {
  return params.data ? <ActionCellRenderer data={params.data} /> : null;
};

export default renderActionCell;
