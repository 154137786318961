import { client } from "../client";
import { IGitWeightings } from "../../models/Apps/gitWeightings.interface";

export const GitWeightingsService = {
  get: async () : Promise<IGitWeightings> => {
    const response = await client.get(`/git_weightings`);

    return {

      defaultWeightings: response.data.defaultWeightings,
      // TODO: temporarily set this until we persist customWeightings
      // customWeightings: response.data.customWeightings
      customWeightings: response.data.defaultWeightings
    };
  },
};
