import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';

// TODO: turn into a public interface
interface PullRequest {
  number: number;
  title: string;
  url: string;
}

export default function PullRequestCellRenderer(props: ICellRendererParams) {
  const { data } = props;

  // Check if the data or pull requests data is available
  if (!data || !data.pullRequests) {
    return null; // Return null to render nothing in the cell
  }

  // Handle single or multiple pull requests
  const pullRequests = Array.isArray(data.pullRequests) ? data.pullRequests : [data.pullRequests];

  return (
    <>
      {pullRequests.map((pr: PullRequest, index: number) => (
        <React.Fragment key={pr.number}>
          <a href={pr.url} target="_blank" rel="noopener noreferrer">
            {pr.number}
          </a>
          {index < pullRequests.length - 1 && ', '}
        </React.Fragment>
      ))}
    </>
  );
}
