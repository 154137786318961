import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { authApi } from '../features/auth/api/authApi';
import { githubStatsApi } from '../features/githubStats/api/githubStatsApi';
import { githubStatRangesApi } from '../features/githubStatsRange/api/githubStatRangeApi';
import { invitationApi } from '../features/People/api/invitationApi';
import { peopleApi } from '../features/People/api/peopleApi';
import { workspaceApi } from '../features/setup/workspace/api/workspaceApi';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      githubStatsApi.middleware,
      githubStatRangesApi.middleware,
      invitationApi.middleware,
      peopleApi.middleware,
      workspaceApi.middleware
    ]),
});

// Define and export RootState and AppDispatch types
type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export type { RootState, AppDispatch };
export default store;
