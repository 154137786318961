import { ColDef } from "@ag-grid-community/core";
import renderActionCell from './renderActionCell';

export const columnDefs: ColDef[] = [
  {
    field: 'githubUser.name',
    headerName: 'GitHub User',
    flex: 1,
    pinned: 'left',
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ["reset"],
      debounceMs: 1000,
      filterOptions: ["contains"],
      maxNumConditions: 1,
    }
  },
  {
    field: 'githubUser.login',
    headerName: 'User Login',
    flex: 1, pinned: 'left',
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ["reset"],
      debounceMs: 1000,
      filterOptions: ["contains"],
      maxNumConditions: 1,
    }
  },
  {
    field: 'totals.totalCollaboration',
    headerName: 'Total Collaboration',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.totalCommits',
    headerName: 'Total Commits',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.totalLoc',
    headerName: 'Total LOC',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.totalMerges',
    headerName: 'Total Merges',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.totalMf',
    headerName: 'Total MF',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.featureDevLoc',
    headerName: 'Feature Dev LOC',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.featureDevMf',
    headerName: 'Feature Dev MF',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.bugFixLoc',
    headerName: 'Bug Fix LOC',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.bugFixMf',
    headerName: 'Bug Fix MF',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.testLoc',
    headerName: 'Test LOC',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.testMf',
    headerName: 'Test MF',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.choreLoc',
    headerName: 'Chore LOC',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.choreMf',
    headerName: 'Chore MF',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.performanceLoc',
    headerName: 'Performance LOC',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.performanceMf',
    headerName: 'Performance MF',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.refactorLoc',
    headerName: 'Refactor LOC',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.refactorMf',
    headerName: 'Refactor MF',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.docsLoc',
    headerName: 'Docs LOC',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.docsMf',
    headerName: 'Docs MF',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.branchMerge',
    headerName: 'Branch Merge',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.prMerge',
    headerName: 'PR Merge',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.revert',
    headerName: 'Revert',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.commitComments',
    headerName: 'Commit Comments',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.createdIssues',
    headerName: 'Created Issues',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.closedIssues',
    headerName: 'Closed Issues',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.issueComments',
    headerName: 'Issue Comments',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.createdMergeRequests',
    headerName: 'Created Merge Requests',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.mergedMergeRequests',
    headerName: 'Merged Merge Requests',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.mergeRequestComments',
    headerName: 'Merge Request Comments',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'totals.mergeRequestReviews',
    headerName: 'Merge Request Reviews',
    flex: 1,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    field: 'zScores.total',
    headerName: 'Score',
    flex: 1,
    pinned: 'right',
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  },
  {
    headerName: 'Actions',
    cellRenderer: renderActionCell,
    pinned: 'right',
  },
];
