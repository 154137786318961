import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { OrganisationsService } from '../../services/Workspace/Apps/Github/OrganisationsService';
import { IOrganisation } from '../../models/organisation.interface';

const OrganisationsPage = () => {
  const [organisations, setOrganisations] = useState<IOrganisation[]>([]);

  useEffect(() => {
    OrganisationsService.getRepos()
      .then(function (response) {
        setOrganisations(response);
      });
  }, []);

  console.log(organisations);

  return (
    <List>
      {organisations.map((org: IOrganisation) => (
        <ListItem key={org.id}>
          <ListItemText primary={org.name} />
        </ListItem>
      ))}
    </List>
  );
};

export default OrganisationsPage;
