import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '../features/auth/slices/userSlice';
import githubStatsReducer from '../features/Workspace/Apps/Github/githubStatsSlice';
import gitWeightingsReducer from '../features/Workspace/Apps/Github/gitWeightingsSlice';
import { authApi } from '../features/auth/api/authApi';
import { githubStatsApi } from '../features/githubStats/api/githubStatsApi';
import { githubStatRangesApi } from '../features/githubStatsRange/api/githubStatRangeApi';
import { invitationApi } from '../features/People/api/invitationApi';
import { peopleApi } from '../features/People/api/peopleApi';
import { workspaceApi } from '../features/setup/workspace/api/workspaceApi';

const rootReducer = combineReducers({
  user: userReducer,
  githubStats: githubStatsReducer,
  gitWeightings: gitWeightingsReducer,
  [authApi.reducerPath]: authApi.reducer,
  [peopleApi.reducerPath]: peopleApi.reducer,
  [githubStatsApi.reducerPath]: githubStatsApi.reducer,
  [githubStatRangesApi.reducerPath]: githubStatRangesApi.reducer,
  [invitationApi.reducerPath]: invitationApi.reducer,
  [workspaceApi.reducerPath]: workspaceApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
