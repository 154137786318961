import { client } from "../../../client";

type UpdateBotResponse = {
  success: boolean;
  message: string;
};

export const GithubUserService = {
  setExclusionDetails: async (githubUserId: number, exclusionReason: string): Promise<UpdateBotResponse> => {
    const response = await client.post(`/github_users/${githubUserId}/set_exclusion_details`, { github_user: { exclusion_reason: exclusionReason } });
    return response.data;
  },
};
