import React from 'react';
import {
  Container,
  Typography
} from '@mui/material';
import GithubApp from '../../../common/components/Apps/GithubApp';

const SetupAppsPage = () => {
  return (
    <Container>
      <Typography variant="h4" component="h1">
        {/* Connect your Apps */}
        Connect to Github
      </Typography>
      <Typography variant="body1" component="h1" sx={{ mb: 4 }}>
        {/* An App is a tool you&apos;re currently using with your team. You can
        install an App to your Workspace and we&apos;ll give you the option
        to tell us what you want analysed. */}
        {/* Connect your Apps to your Workspace and then in the next step decide what you want us to analyse */}
      </Typography>
      <GithubApp />
    </Container>
  );
};

export default SetupAppsPage;
