import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../../common/types/user.interface';
import { RootState } from '../../../app/store';

const initialState: IUser = {
  name: '',
  avatar: '',
  isLoggedIn: false,
  currentMembership: {
    role: '',
    workspaceName: '',
    workspaceSetupComplete: false,
    // analysisMinDate: '',
    analysisPeriod: '',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser>) {
      state.name = action.payload.name;
      state.avatar = action.payload.avatar;
      state.isLoggedIn = action.payload.isLoggedIn;
      state.currentMembership = action.payload.currentMembership;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setMembershipRole(state, action: PayloadAction<string>) {
      state.currentMembership.role = action.payload;
    },
    // setMembershipId(state, action: PayloadAction<number>) {
    //   state.membershipId = action.payload;
    // },
    setCurrentWorkspaceName(state, action: PayloadAction<string>) {
      state.currentMembership.workspaceName = action.payload;
    },
    setCurrentWorkspaceSetupComplete(state, action: PayloadAction<boolean>) {
      state.currentMembership.workspaceSetupComplete = action.payload;
    },
    // setAnalysisMinDate(state, action: PayloadAction<string>) {
    //   state.analysisMinDate = action.payload;
    // },
    // setAnalysisPeriod(state, action: PayloadAction<string>) {
    //   state.analysisPeriod = action.payload;
    // },
    // clearUserData: (state) => {
    //   Object.assign(state, initialState);
    // },
    markLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.name = '';
      state.avatar = '';
      state.currentMembership.role = '';
      state.currentMembership.workspaceName = '';
      state.currentMembership.workspaceSetupComplete = false;
      // state.currentMembership.analysisMinDate = '';
      state.currentMembership.analysisPeriod = '';
      localStorage.removeItem('user_name');
      localStorage.removeItem('user_avatar');
      localStorage.removeItem('currentMembership_role');
      localStorage.removeItem('currentMembership_workspaceName');
      localStorage.removeItem('currentMembership_workspaceSetupComplete');
      // localStorage.removeItem('currentMembership_analysisMinDate');
      localStorage.removeItem('currentMembership_analysisPeriod');
    },
  },
});

export const {
  setUser,
  setIsLoggedIn,
  setMembershipRole,
  // setMembershipId,
  setCurrentWorkspaceName,
  setCurrentWorkspaceSetupComplete,
  // setAnalysisMinDate,
  // setAnalysisPeriod,
  // clearUserData,
  markLoggedIn,
  logout
} = userSlice.actions;

export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn;
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
