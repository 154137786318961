import React, { useCallback, useMemo, useState } from 'react';
// import { Box, CircularProgress, Typography } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  ModuleRegistry,
  GridOptions,
  FilterModel
} from "@ag-grid-community/core";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import ShaCellRenderer from '../ShaCellRenderer';
import { useLazyGetCommitsByGithubStatQuery } from '../../api/githubStatsApi';
import { ICommitRowData } from '../../types/commitTypes';
import PullRequestCellRenderer from '../PullRequestCellRenderer';

ModuleRegistry.registerModules([RowGroupingModule, ServerSideRowModelModule]);

interface CommitsGridByTypeProps {
  githubStatId: string;
  isDarkMode: boolean;
}

const CommitsGridByType = ({
  githubStatId,
  isDarkMode,
}: CommitsGridByTypeProps) => {
  const gridClassName = useMemo(() => {
    return isDarkMode ? "ag-theme-quartz-dark" : "ag-theme-quartz";
  }, [isDarkMode]);
  const gridStyle = useMemo(() => ({ height: "500px", width: "100%" }), []);

  const [fetchData] = useLazyGetCommitsByGithubStatQuery();

  const [columnDefs] = useState<ColDef[]>([
    { field: 'classification', rowGroup: true, hide: true },
    { field: 'message', headerName: 'Message', flex: 1 },
    {
      field: 'modifiedLoc',
      headerName: 'Modified Loc',
      flex: 1,
      valueFormatter: (params) => {
        return params.value.toLocaleString();
      },
    },
    {
      field: 'modifiedFiles',
      headerName: 'Modified Files',
      flex: 1,
      valueFormatter: (params) => {
        return params.value.toLocaleString();
      },
    },
    { field: 'url', headerName: 'SHA', flex: 1, cellRenderer: ShaCellRenderer },
    { field: 'authoredAt', headerName: 'Authored At', flex: 1 },
    { field: 'committedAt', headerName: 'Committed At', flex: 1 },
    { field: 'repository.name', headerName: 'Repository', flex: 1 },
    {
      field: 'pullRequests',
      headerName: 'Pull Request',
      flex: 2,
      cellRenderer: PullRequestCellRenderer,
      sortable: false,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      // sortable: true,
    };
  }, []);

  // const autoGroupColumnDef: ColDef = useMemo(() => {
  //   return {
  //     flex: 1,
  //     minWidth: 200,
  //     headerName: 'Category',
  //     field: 'message',
  //   };
  // }, []);

  const getChildCount = useCallback((data: ICommitRowData) => {
    return data?.childCount ?? 0;
  }, []);

  const gridOptions: GridOptions = useMemo(() => {
    return {
      rowModelType: "serverSide",
      cacheBlockSize: 50,
      maxBlocksInCache: 2,
      blockLoadDebounceMillis: 500,
      rowBuffer: 0,
      // overlayNoRowsTemplate: "No data available to display",
      // debug: true,
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    const datasource = {
      getRows: (params: IServerSideGetRowsParams) => {
        const startRow = params.request.startRow ?? 0;
        const endRow = params.request.endRow ?? 50;
        const sortModel = params.request.sortModel;
        const filterModel = params.request.filterModel as FilterModel | undefined;
        const rowGroupCols = (params.request.rowGroupCols || [])
        const valueCols = params.request.valueCols || [];
        const groupKeys = params.request.groupKeys || [];

        // console.log("rowGroupCols: ", rowGroupCols);
        fetchData({ githubStatId, startRow, endRow, rowGroupCols, valueCols, groupKeys, sortModel, filterModel }).then((result) => {
          // console.log("result: ", result.data);
          if (result.data) {
            // console.log("result.data: ", result.data);
            // console.log("result.data.commits: ", result.data.commits);
            params.success({
              rowData: result.data.commits,
              rowCount: result.data.rowCount
            });
            if (result.data.commits.length === 0) {
              params.api?.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        }).catch(() => {
          params.fail();
        });
      }
    };
    params.api?.setGridOption("serverSideDatasource", datasource);
  }, [fetchData]);

  return (
    <div
      className={gridClassName}
      style={gridStyle}
    >
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // autoGroupColumnDef={autoGroupColumnDef}
        getChildCount={getChildCount}
        onGridReady={onGridReady}
        gridOptions={gridOptions}
      />
    </div>
  );
};

export default CommitsGridByType;
