import React from 'react'
import { useAppSelector } from '../../../app/hooks';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountMenu from './AccountMenu';
import SsoLogin from '../../../features/auth/components/SsoLogin';
// import CircularProgress from '@mui/material/CircularProgress';
import styles from './TopNav.module.css';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const drawerWidth = 240;

// function Loader() {
//   return (
//     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//       <CircularProgress />
//     </div>
//   );
// }

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TopNav() {
  const userSlice = useAppSelector(state => state.user);

  return (
    <>
      {/* <CssBaseline /> */}
      <AppBar>
        <Toolbar>
          <img
            className={styles['logo-circle']}
            src='/assets/rabbit-space.svg'
            alt="ProdOps HQ"
          />
          <Typography
            variant="h5"
            noWrap
            sx={{
              flexGrow: 1,
              sm: 'block',
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 900,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            ProdOps HQ
          </Typography>
          {userSlice.name && userSlice.currentMembership.workspaceName && (
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Button sx={{ color: '#fff' }}>
                <Typography variant="subtitle1">
                  {userSlice.currentMembership.workspaceName}
                </Typography>
                {/* <ExpandMoreIcon /> */}
              </Button>
            </Box>
          )}
          <Box sx={{ flexGrow: 0, ml: 2 }}>
            {/* {!userSlice.isLoggedIn ? (
              <Loader />
            ) :  */}
            {userSlice.name ? (
              userSlice.name && userSlice.avatar && (
                <AccountMenu />
              )
            ) : (
              <SsoLogin />
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
