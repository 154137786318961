import { client } from "../../../client";
import { IApp } from "../../../../models/Workspace/app.interface";

export const RepositoryService = {
  get: async (id: number): Promise<IApp> => {
    const response = await client.get(`/apps/${id}/repositories`);
    return response.data;
  },
  import: async (id: number, selectedRows: number[]): Promise<void> => {
    try {
      await client.post(`/apps/${id}/repositories/import`, {
        repository: {
          selected_rows: selectedRows
        }
      });

      // Handle the success case as needed
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  }
};
