import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../app/baseQuery';
import { IWorkspace } from "../../../../common/types/workspace.interface";

export const workspaceApi = createApi({
  reducerPath: 'workspaceApi',
  baseQuery: baseQuery,
  tagTypes: ['Workspace'],
  endpoints: (builder) => ({
    getWorkspaces: builder.query<IWorkspace[], void>({
      query: () => `/workspaces`,
    }),
    createWorkspace: builder.mutation<IWorkspace, Partial<IWorkspace>>({
      query: (workspaceData) => ({
        url: '/workspaces',
        method: 'POST',
        body: {
          workspace: workspaceData
        },
      }),
    }),
  }),
});

export const {
  useGetWorkspacesQuery,
  useCreateWorkspaceMutation,
} = workspaceApi;


// interface ICreateWorkspaceResponse {
//   data: IWorkspace;
//   status: number;
// }
//
// export const WorkspaceService = {
//   list: async (): Promise<IWorkspace[]> => {
//     const response = await client.get("/workspaces");
//     return response.data;
//   },
//   create: async (workspace: IWorkspace): Promise<ICreateWorkspaceResponse> => {
//     try {
//       const response: AxiosResponse = await client.post("/workspaces", { workspace });
//       return {
//         data: response.data,
//         status: response.status
//       };
//     return response.data;
//     } catch (error) {
//       throw error;
//     }
//   },
//   update: async (workspace: IWorkspace): Promise<IWorkspace> => {
//     const response = await client.put(`/workspaces/${workspace.id}`, { workspace });
//     return response.data;
//   }
// };
