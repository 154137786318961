import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../app/baseQuery';
import { IUser } from '../../../common/types/user.interface';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUserDetails: builder.query<IUser, void>({
      query: () => 'user',
      transformResponse: (response: IUser) => {
        return response;
      },
    }),
  }),
});

export const { useGetUserDetailsQuery } = authApi;
