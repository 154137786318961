import React, { useEffect, useState } from 'react'
import { IApp } from '../../../../../models/Workspace/app.interface';
import { Button, Card, CardContent, Chip, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Tab, Tabs, Typography } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useNavigate } from 'react-router-dom';
import { AppsService } from '../../../../../services/Workspace/Apps/AppsService';
import GithubApp from '../../../../../common/components/Apps/GithubApp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FormatListBulleted, DateRange, MoreHoriz } from '@mui/icons-material';

export default function IntegrationsPage() {
  const navigate = useNavigate();
  const [apps, setApps] = useState<IApp[]>([]);
  const [tab, setTab] = useState<number>(0);

  const [anchorEl, setAnchorEl] = React.useState<{ [key: string]: null | HTMLElement }>({});
  const open = Boolean(anchorEl);

  const handleClick = (appId: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(prevState => ({ ...prevState, [appId]: event.currentTarget }));
  };

  const handleClose = (appId: number) => {
    setAnchorEl(prevState => ({ ...prevState, [appId]: null }));
  };

  useEffect(() => {
    // TODO: create a hook
    AppsService.list()
      .then(function (response) {
        // Handle the response from RepositoryService.get here
        setApps(response);
      })
      .catch(function (repoError) {
        // Handle any errors from RepositoryService.get here
        console.error(repoError);
      });
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleReconnectClick = () => {
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/apps/github`;
  };

  return (
    <div>
      <Tabs value={tab} onChange={handleChange} style={{ marginBottom: 20 }} >
        <Tab label="Manage your Apps" />
        <Tab label="Connect new Apps" />
      </Tabs>
      <div
        role="tabpanel"
        hidden={tab !== 0}
        id="simple-tabpanel-0"
        aria-labelledby="simple-tab-0"
      >
        {apps.map((app) => (
          <Card key={app.id} sx={{ minWidth: 275, marginBottom: 2 }}>
            <CardContent sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <GitHubIcon fontSize="large" sx={{ marginRight: 2 }} />
                <div>
                  <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                    {app.organisation}
                    {app.connected && (
                      <Button
                        size="small"
                        color="secondary"
                        sx={{ marginLeft: 2 }}
                        onClick={() => window.open(`https://github.com/organizations/${app.organisation}/settings/installations/${app.installation_id}`, '_blank')}
                        endIcon={<OpenInNewIcon />}
                      >
                        github.com
                      </Button>
                    )}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                    Analysing {app.bucket_count} repositories
                  </Typography>
                  {app.connected ? (
                    <Chip label="Connected" color="success" variant="outlined" size="small" />
                  ) : (
                    <Chip label="Not connected" color="error" variant="outlined" size="small" />
                  )}
                </div>
              </div>
              {app.connected ? (
                <div>
                  <Button
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick(app.id)}
                  >
                    <MoreHoriz />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl[app.id]}
                    open={Boolean(anchorEl[app.id])}
                    onClose={() => handleClose(app.id)}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuList>
                      <MenuItem onClick={() => navigate(`/apps/github/${app.id}/repositories`)}>
                        <ListItemIcon>
                          <FormatListBulleted fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Manage Repositories</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => navigate(`/apps/github/${app.id}/stats`)}>
                        <ListItemIcon>
                          <DateRange fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Manage Stats Insights</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleReconnectClick}
                  color="secondary"
                >
                  Reconnect
                </Button>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
      <div
        role="tabpanel"
        hidden={tab !== 1}
        id="simple-tabpanel-1"
        aria-labelledby="simple-tab-1"
      >
        <GithubApp />
      </div>
    </div>
  )
}
