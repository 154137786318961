import React, { useEffect, useState } from 'react';
import './App.css';
import { useAppSelector, useAppDispatch } from './hooks';
import { setUser, selectUser, setIsLoggedIn } from '../features/auth/slices/userSlice';
import SideNav from '../common/components/Navigation/SideNav';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import TopNav from '../common/components/Navigation/TopNav';
import Cookies from 'js-cookie';
import { Navigate, Route, Routes } from 'react-router-dom';
import DrawerHeader from '../common/components/Navigation/DrawerHeader';
import { Box, CircularProgress, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RepositoriesImportPage from '../features/Repositories/Import';
import OrganisationsPage from '../features/Organisations';
import SetupAppsPage from '../features/setup/Apps';
import WorkspacePage from '../features/setup/workspace';
import DashboardPage from '../features/Dashboard';
import PeoplePage from '../features/People/PeoplePage';
import MembershipPage from '../features/setup/Membership/MembershipPage';
import RepositoriesPage from '../features/Workspace/Apps/Github/Repositories/RepositoriesPage';
import GithubIntegrationsPage from '../features/Workspace/Apps/Github/Integrations/Integrations';
import GithubStatsRangePage from '../features/githubStatsRange';
import ManageStatsPage from '../features/Workspace/Apps/Github/ManageStats/ManageStats';
import getTheme from '../theme/theme';
import { useGetUserDetailsQuery } from '../features/auth/api/authApi';
import GithubStatsPage from '../features/githubStats';

// function isAnalysisPeriod(value: string | undefined | null): value is 'week' | 'three_months' | 'six_months' | 'year' | null {
//   return value === 'week' || value === 'three_months' || value === 'six_months' || value === 'year' || value === null;
// }

interface RouteProps {
  children: React.ReactNode;
  accountName: string | null;
}

const ProtectedRoute = ({ children, accountName }: RouteProps) => {
  return accountName ? <>{children}</> : <Navigate to="/" />;
};

function App() {
  const [loading, setLoading] = useState(true);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userSlice = useAppSelector(selectUser);
  const csrfToken = Cookies.get('csrf_token');

  useEffect(() => {
    if (csrfToken) {
      dispatch(setIsLoggedIn(true));
    } else {
      dispatch(setIsLoggedIn(false));
    }
  }, [csrfToken, dispatch]);

  // const { data: userDetails, isLoading: userDetailsLoading, refetch } = useGetUserDetailsQuery(undefined, { skip: !userSlice.isLoggedIn });
  const { data: userDetails, isLoading: userDetailsLoading } = useGetUserDetailsQuery(undefined, { skip: !userSlice.isLoggedIn });

  const theme = React.useMemo(() =>
    getTheme(prefersDarkMode),
    [prefersDarkMode]
  );

  // Check Local Storage First
  useEffect(() => {
    const userName = localStorage.getItem('user_name');
    const userAvatar = localStorage.getItem('user_avatar');
    const workspaceName = localStorage.getItem('currentMembership_workspaceName');
    const workspaceSetupCompleteString = localStorage.getItem('currentMembership_workspaceSetupComplete');
    const workspaceSetupComplete = workspaceSetupCompleteString === 'true';
    // const workspaceAnalysisMinDate = localStorage.getItem('currentMembership_analysisMinDate');
    const workspaceAnalysisPeriod = localStorage.getItem('currentMembership_analysisPeriod');
    const userRole = localStorage.getItem('currentMembership_role');

    if (userName && userAvatar && workspaceName && workspaceSetupComplete && workspaceAnalysisPeriod && userRole) {
      dispatch(setUser({
        name: userName,
        avatar: userAvatar,
        isLoggedIn: true,
        currentMembership: {
          role: userRole,
          workspaceName: workspaceName,
          workspaceSetupComplete: workspaceSetupComplete,
          // analysisMinDate: workspaceAnalysisMinDate,
          analysisPeriod: workspaceAnalysisPeriod,
        }
      }));
    }

    setLoading(false);
  }, [dispatch])

  // updates local storage and Redux store when new user details are fetched from the API.
  useEffect(() => {
    if (userDetails) {
      localStorage.setItem('user_name', userDetails.name);
      localStorage.setItem('user_avatar', userDetails.avatar);
      localStorage.setItem('currentMembership_role', userDetails.currentMembership?.role);
      localStorage.setItem('currentMembership_workspaceName', userDetails.currentMembership?.workspaceName);
      localStorage.setItem('currentMembership_workspaceSetupComplete', userDetails.currentMembership?.workspaceSetupComplete.toString());
      // localStorage.setItem('currentMembership_analysisMinDate', userDetails.currentMembership?.analysisMinDate);
      localStorage.setItem('currentMembership_analysisPeriod', userDetails.currentMembership?.analysisPeriod);

      dispatch(setUser({
        name: userDetails.name,
        avatar: userDetails.avatar,
        isLoggedIn: true,
        currentMembership: {
          role: userDetails.currentMembership?.role,
          workspaceName: userDetails.currentMembership?.workspaceName,
          workspaceSetupComplete: userDetails.currentMembership?.workspaceSetupComplete,
          // analysisMinDate: userDetails.currentMembership?.analysisMinDate,
          analysisPeriod: userDetails.currentMembership?.analysisPeriod,
        }
      }));
    }
  }, [userDetails, dispatch]);

  useEffect(() => {
    if (userSlice && userSlice.name) {
      if (!userSlice.currentMembership?.workspaceName) {
        navigate('/setup/workspace');
      }
      else if (!userSlice.currentMembership.role) {
        navigate('/setup/membership');
      }
      else if (
        userSlice?.currentMembership.workspaceName &&
        !userSlice?.currentMembership.workspaceSetupComplete
      ) {
        navigate('/setup/apps');
      }
    }
  }, [userSlice, navigate]);

  if (loading || userDetailsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <TopNav
            // isLoading={isLoading}
          />
          {
            (
              userSlice.name &&
              userSlice.currentMembership.workspaceName &&
              userSlice.currentMembership.workspaceSetupComplete === true
            ) ? <SideNav /> : null
          }
          <DrawerHeader />
          <Container
            maxWidth={false}
            sx={{
              width: "96%",
            }}
          >
            <DrawerHeader />
            <Routes>
              {/* Sign-in page required for / and unauthenticated users */}
              {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<ProtectedRoute accountName={userSlice.name}><DashboardPage /></ProtectedRoute>} />
              <Route path="/people" element={<ProtectedRoute accountName={userSlice.name}><PeoplePage /></ProtectedRoute>} />
              <Route path="/setup/membership" element={<ProtectedRoute accountName={userSlice.name}><MembershipPage /></ProtectedRoute>} />
              <Route path="/setup/workspace" element={<ProtectedRoute accountName={userSlice.name}><WorkspacePage /></ProtectedRoute>} />
              <Route path="/apps" element={<ProtectedRoute accountName={userSlice.name}><GithubIntegrationsPage /></ProtectedRoute>} />
              <Route path="/organisations" element={<ProtectedRoute accountName={userSlice.name}><OrganisationsPage /></ProtectedRoute>} />
              <Route path="/repositories_import" element={<ProtectedRoute accountName={userSlice.name}><RepositoriesImportPage /></ProtectedRoute>} />
              <Route path="/setup/apps" element={<ProtectedRoute accountName={userSlice.name}><SetupAppsPage /></ProtectedRoute>} />
              <Route path="/apps/github/:id/repositories" element={<ProtectedRoute accountName={userSlice.name}><RepositoriesPage /></ProtectedRoute>} />
              {/* <Route path="/apps/github/:id/stats" element={<ManageStatsPage />} /> */}
              <Route path="/apps/github/manage_stats" element={<ProtectedRoute accountName={userSlice.name}><ManageStatsPage /></ProtectedRoute>} />
              <Route path="/github_stats_range/:id" element={<ProtectedRoute accountName={userSlice.name}><GithubStatsRangePage /></ProtectedRoute>} />
              {/* <Route path="/github_user/:userId" element={<ProtectedRoute accountName={userSlice.name}><GithubStatsPage /></ProtectedRoute>} /> */}
              <Route path="/github_stats/:id" element={<ProtectedRoute accountName={userSlice.name}><GithubStatsPage /></ProtectedRoute>} />
              {/* Catch-all route for 404 Not Found page */}
              {/* TODO: display a message that a route doesn't exist? */}
              {/* <Route path="*" element={<NotFoundComponent />} /> */}
              {/* <Route path="*" element={accountName ? <DashboardPage /> : } /> */}
              <Route path="*" element={<NoMatch />} /> {/* Update this route */}
            </Routes>
          </Container>
        </Box>
      </ThemeProvider>
    </>
  );
}

function NoMatch() {
  return (
    <div>
      <h3>404 - Not Found</h3>
    </div>
  );
}

export default App;
