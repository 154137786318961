import React, { useState } from 'react';
import { Box, Button, Typography, Modal, TextField } from '@mui/material';
import { green } from '@mui/material/colors';
import { useCreateInvitationMutation } from '../api/invitationApi';

interface InviteModalProps {
  open: boolean;
  workspaceName: string;
  onClose: () => void;
}

export default function InviteModal({ open, workspaceName, onClose }: InviteModalProps) {
  const [email, setEmail] = useState('');
  const [createInvitation, { isLoading, isError, error }] = useCreateInvitationMutation();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleInvite = async () => {
    if (email) {
      try {
        await createInvitation({ email }).unwrap();
        setEmail('');  // Clear the email field if the invitation is successful
        onClose();     // Close the modal on success
      } catch (err) {
        console.error('Failed to send invitation:', err);
      }
    }
  };

  const handleClose = () => {
    setEmail('');  // Reset the email field
    onClose();     // Propagate the close action up to the parent component
  };

  // const getErrorMessage = (error: any): string => {
  //   if ('data' in error && error.data && 'message' in error.data) {
  //     return error.data.message;
  //   } else if ('error' in error && typeof error.error === 'string') {
  //     return error.error;
  //   }
  //   return 'Failed to send invitation';  // Default error message
  // };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="invite-modal-title"
      aria-describedby="invite-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          border: 1,
          borderRadius: 2,
          borderColor: 'grey.500',
      }}>
        <Typography id="invite-modal-title" variant="h6" component="h2">
          Invite people to {workspaceName}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          variant="outlined"
        />
        <Button
          onClick={handleInvite}
          variant="contained"
          sx={{ backgroundColor: green[600], '&:hover': { backgroundColor: green[700] } }}
        >
          {isLoading ? 'Inviting...' : 'Invite'}
        </Button>
        {/* {isError && <Typography color="error">Error: {getErrorMessage(error)}</Typography>} */}
        {isError && error && 'message' in error && (
          <Typography color="error">Error: {error.message}</Typography>
        )}
      </Box>
    </Modal>
  );
}
