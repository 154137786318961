import React from 'react'
import { Box, TableCell, Tooltip, Typography } from '@mui/material'

interface Props {
  alignment: "right" | "left" | "center" | "inherit" | "justify" | undefined;
  tooltip: string;
  heading: string;
}

export default function StatTableHeaderCell({alignment, tooltip, heading}: Props) {
  return (
    <TableCell align={alignment}>
      <Tooltip title={tooltip} placement={(heading == "Developer" || heading == "Total") ? "top-start" : "top"}>
        <Box display="flex" alignItems="center" justifyContent={alignment}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', pr: 1 }} >
            {heading}
          </Typography>
        </Box>
      </Tooltip>
    </TableCell>
  )
}
