import React, { useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { setMembershipRole } from '../../auth/slices/userSlice';
import { Button, TextField, Typography, MenuItem, FormGroup, FormControlLabel, Checkbox, FormLabel } from '@mui/material';
import { styled } from '@mui/system';
import { IMembership } from '../../../models/membership.interface';
import { useNavigate } from "react-router-dom";
import { useUpdateMembershipMutation } from '../../People/api/peopleApi';


const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const defaultSetupMode: IMembership = {
  role: '',
  setup_status: '',
  last_accessed_at: '',
  name: '',
  email: '',
  intentions: []
}

const roles = [
  'Board Member',
  'Developer',
  'Engineering Leader',
  'Executive',
  'Product Manager',
  'Product Leader',
  'Operations Manager',
  'Senior Manager',
];

const intentions = [
  'alignment',
  'collaboration',
  'efficiency',
  'performance',
  'quality',
  'return on investment',
];

export default function MembershipPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [membership, setMembership] = useState<IMembership>(defaultSetupMode);
  const [updateMembership] = useUpdateMembershipMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setMembership((prevMembership) => ({
      ...prevMembership,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await updateMembership({
        data: {
          role: membership.role,
          intentions: membership.intentions
        }
      }).unwrap();

      dispatch(setMembershipRole(response.role));
      navigate('/dashboard');

    } catch (error) {
      console.log('Error:', error);
    }
  };

  const handleIntentionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    setMembership((prevMembership) => {
      let updatedIntentions = prevMembership.intentions;

      if (checked) {
        // Add the intention if it is checked and not already in the array
        if (!updatedIntentions || !updatedIntentions.find(intention => intention.intent === value)) {
          updatedIntentions = [...(updatedIntentions || []), { intent: value }];
        }
      } else {
        // Remove the intention if it is unchecked and present in the array
        updatedIntentions = updatedIntentions?.filter(intention => intention.intent !== value);
      }

      return {
        ...prevMembership,
        intentions: updatedIntentions,
      };
    });
  };

  return (
    <Container sx={{ minWidth: 400 }}>
      <Typography variant="body1" component="h1" sx={{ mb: 4 }}> {/* Add margin bottom */}
        Help us understand how we can personalise your experience
      </Typography>
      <Form onSubmit={handleFormSubmit}>
        <TextField
          select
          label="I am a..."
          name="role"
          value={membership.role}
          onChange={handleChange}
          required
        >
           {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </TextField>
        <FormGroup>
          <FormLabel component="legend">wanting to understand...</FormLabel>
          {intentions.map((intent) => (
            <FormControlLabel
              key={intent}
              control={
                <Checkbox
                  checked={membership.intentions?.some(intention => intention.intent === intent) ?? false}
                  onChange={handleIntentionChange}
                  name="intentions"
                  value={intent}
                />
              }
              label={intent}
            />
          ))}
        </FormGroup>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={membership.intentions?.length === 0 ?? true}
        >
          Create
        </Button>
      </Form>
    </Container>
  );
}
