import * as React from 'react';
import { Button } from '@mui/material';
import { styled, css, keyframes } from '@mui/system';
import { useTheme } from '@mui/material/styles';

const SsoLogin = () => {
  const theme = useTheme();
  const baseColor = theme.palette.mode === 'light'
    ? theme.palette.warning.light
    : theme.palette.primary.main;
  const borderColor = 'white';
  const pulseColor = theme.palette.mode === 'light'
    ? theme.palette.warning.main
    : theme.palette.primary.dark;

  const pulse = keyframes`
    0%, 100% {
      background-color: ${baseColor};
    }
    50% {
      background-color: ${pulseColor};
    }
  `;

  const PulseButton = styled(Button)(
    css`
      text-transform: none;
      background-color: ${baseColor};
      // color: white;
      border: 1px solid ${borderColor};
      animation: ${pulse} 5s infinite ease-in-out;
      &:hover {
        background-color: ${pulseColor};
        transform: scale(1.05);
        animation: none; // Add this line to stop the animation on hover
      }
      &:active {
        background-color: ${pulseColor};
        transform: scale(1.05);
      }
    `,
  );

  const SSO_URL = `${process.env.REACT_APP_AUTH_URL}/users/sign_in`;

  return (

    <div>
      <PulseButton
        variant="contained"
        onClick={() => (window.location.href = SSO_URL)}
      >
        GET STARTED!
      </PulseButton>
    </div>
  );
};

export default SsoLogin;
