import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../app/baseQuery';
import { IMembership } from '../../../models/membership.interface';

export const peopleApi = createApi({
  reducerPath: 'peopleApi',
  baseQuery: baseQuery,
  tagTypes: ['Membership'],
  endpoints: (builder) => ({
    getMemberships: builder.query<IMembership[], void>({
      query: () => `/memberships`,
    }),
    updateMembership: builder.mutation<IMembership, { data: Partial<IMembership> }>({
      query: ({ data }) => ({
        url: `/memberships/update_current`,
        method: 'PUT',
        body: {
          membership: {
            intentions_attributes: data.intentions?.map((intention) => ({
              id: intention.id, // assuming each intent has an id, you might adjust this if it's differently structured
              intent: intention.intent,
              role: data.role,
              _destroy: intention._destroy // This should be a boolean to indicate whether the intent should be deleted
            }))
          }
        },
      }),
      invalidatesTags: ['Membership'],
    }),
  }),
});

export const {
  useGetMembershipsQuery,
  useUpdateMembershipMutation
} = peopleApi;
