import React from 'react';
import { Box, Divider, Tooltip, Typography, useTheme } from '@mui/material'
// import { useAppSelector } from '../../app/hooks';
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
// import { ICommit } from './types/commitTypes';
import { useParams } from 'react-router-dom';
// import ShaCellRenderer from './components/ShaCellRenderer';
// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// import { ColDef, ModuleRegistry } from '@ag-grid-community/core';
// import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
// import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
// import ExcludedGithubStatsGrid from './components/grid/ExcludedGithubStatsGrid';
import CommitsGridByType from './components/grid/CommitsGridByType';
import { useGetGithubStatQuery } from './api/githubStatsApi';

// ModuleRegistry.registerModules([
//   ClientSideRowModelModule,
//   RowGroupingModule,
//   StatusBarModule,
// ]);

// const includedStatColumnDefs: ColDef<ICommit>[] = [
//   { field: 'classification', headerName: 'Classification', flex: 1, rowGroup: true, hide: true },
//   { field: 'message', headerName: 'Message', flex: 1, hide: true },
//   { field: 'modifiedLoc', headerName: 'Modified Loc', flex: 1, aggFunc: "sum" },
//   { field: 'modifiedFiles', headerName: 'Modified Files', flex: 1, aggFunc: "sum" },
//   { field: 'url', headerName: 'SHA', flex: 1, cellRenderer: ShaCellRenderer },
//   { field: 'authoredAt', headerName: 'Authored At', flex: 1 },
//   { field: 'committedAt', headerName: 'Committed At', flex: 1 },
//   { field: 'repository.name', headerName: 'Repository', flex: 1 },
// ];

// const excludedColumnDefs: ColDef<ICommit>[] = [
//   { field: 'createdAt', headerName: 'Created At', flex: 1 },
//   { field: 'authoredAt', headerName: 'Authored At', flex: 1 },
//   { field: 'url', headerName: 'SHA', flex: 1, cellRenderer: ShaCellRenderer },
//   { field: 'message', headerName: 'Message', flex: 1 },
//   { field: 'classification', headerName: 'Classification', flex: 1 },
//   { field: 'modifiedLoc', headerName: 'Modified Loc', flex: 1 },
//   { field: 'modifiedFiles', headerName: 'Modified Files', flex: 1 },
//   { field: 'committedAt', headerName: 'Committed At', flex: 1 },
//   { field: 'repository.name', headerName: 'Repository', flex: 1 },
// ];

export default function GithubStatsPage() {
  const { id } = useParams<{ id: string | undefined }>();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  // const userSlice = useAppSelector((state) => state.user);
  // const isLoggedIn = userSlice.isLoggedIn;
  const githubStatId = id || '';

  const { data: githubStat } =  useGetGithubStatQuery({ githubStatId: id });
  // const statusBar = useMemo(() => {
  //   return {
  //     statusPanels: [
  //       { statusPanel: "agTotalRowCountComponent" },
  //       { statusPanel: "agAggregationComponent" },
  //     ],
  //   };
  // }, []);

  return (
    <>
      <h2>GithubStat - {githubStat?.githubUser.login}</h2>
      <Divider sx={{ mb: 2 }} />

      {/* TODO: set width based on screen width */}
      <Box sx={{ mt: 2, width: '20%', maxWidth: '100%' }}>
        {/* list the following meta: githubStat.fromDate */}
        <Typography variant="body2">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Name:</span>
            <span>{githubStat?.githubUser.name}</span>
          </Box>
        </Typography>
        <Typography variant="body2">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Total Collaboration:</span>
            <span>{githubStat?.totals.totalCollaboration.toLocaleString()}</span>
          </Box>
        </Typography>
        <Typography variant="body2">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Total Commits:</span>
            <span>{githubStat?.totals.totalCommits.toLocaleString()}</span>
          </Box>
        </Typography>
        <Typography variant="body2">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Total LOC:</span>
            <span>{githubStat?.totals.totalLoc.toLocaleString()}</span>
          </Box>
        </Typography>
        <Typography variant="body2">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip title="Includes Commit Branch Merges, Commit Reverts, Commit Pull Request Merges and Merged Pull Requests">
              <span style={{ textDecoration: 'underline dotted', cursor: 'help' }}>
                Total Merges
              </span>
            </Tooltip>
            <span>{githubStat?.totals.totalMerges.toLocaleString()}</span>
          </Box>
        </Typography>
        <Typography variant="body2">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Total MF:</span>
            <span>{githubStat?.totals.totalMf.toLocaleString()}</span>
          </Box>
        </Typography>
      </Box>


      <Divider sx={{ mt: 2, mb: 2 }} />

      {/* Debugging only */}
      {/* <h3>Commits for this GithubStat</h3>
      <Box sx={{ flexGrow: 1 }}>
        {isFetching ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
              <CircularProgress />
            </Box>
          ) : isError ? (
            <Typography color="error">Error loading Commit data!</Typography>
          ) : (
            <div
              className={gridClassName}
              style={{ height: 500 }}
            >
              <AgGridReact
                rowData={githubStat?.githubStatCommits || []}
                columnDefs={childColumnDefs}
              />
            </div>
          )}
      </Box> */}

      <h3>Included Commits for Stat</h3>
      <CommitsGridByType
        githubStatId={githubStatId}
        isDarkMode={isDarkMode}
        // className={gridClassName}
        // statusBar={statusBar}
        // includedCommitsForStat={githubStat?.includedCommitsForStat || []}
        // isFetching={isFetching}
        // isError={isError}
      />
      {/* <Box sx={{ flexGrow: 1 }}>
        {isFetching ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Typography color="error">Error loading Commit data!</Typography>
        ) : (
          <div
            className={gridClassName}
            style={{ height: 500 }}
          >
            <AgGridReact
              columnDefs={includedStatColumnDefs}
              rowData={githubStat?.includedCommitsForStat || []}
              autoGroupColumnDef={{
                headerName: 'Category',
                field: 'message',
              }}
              // groupDefaultExpanded={1}
              statusBar={statusBar}
              grandTotalRow={'top'}
            />
          </div>
        )}
      </Box> */}

      <h3>Excluded Commits during this period</h3>
      {/* <ExcludedGithubStatsGrid
        className={gridClassName}
        // statusBar={statusBar}
        excludedCommitsForPeriod={githubStat?.excludedCommitsForPeriod || []}
        isFetching={isFetching}
        isError={isError}
      /> */}
      {/* <Box sx={{ flexGrow: 1 }}>
        {isFetching ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
              <CircularProgress />
            </Box>
          ) : isError ? (
            <Typography color="error">Error loading Commit data!</Typography>
          ) : (
            <div
              className={gridClassName}
              style={{ height: 500 }}
            >
              <AgGridReact
                rowData={githubStat?.excludedCommitsForPeriod || []}
                columnDefs={excludedColumnDefs}
                statusBar={statusBar}
              />
            </div>
          )}
      </Box> */}

      {/* <h3>Included Commits during this period</h3>
      <Box sx={{ flexGrow: 1 }}>
        {isFetching ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
              <CircularProgress />
            </Box>
          ) : isError ? (
            <Typography color="error">Error loading Commit data!</Typography>
          ) : (
            <div
              className={gridClassName}
              style={{ height: 500 }}
            >
              <AgGridReact
                rowData={githubStat?.includedCommitsForPeriod || []}
                columnDefs={excludedColumnDefs}
                statusBar={statusBar}
              />
            </div>
          )}
      </Box> */}
    </>
  )
}
