import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GitWeightingsService } from '../../../../services/Workspace/GithubWeightingsService';
import { IGitWeighting } from '../../../../models/Apps/gitWeightings.interface';

export const fetchGitWeightings = createAsyncThunk(
  'gitWeightings/fetch',
  async () => {
    try {
      const gitWeightings = await GitWeightingsService.get();
      return gitWeightings;
    } catch (error) {
      console.error(error);
    }
  }
);

const gitWeightingsSlice = createSlice({
  name: 'gitWeightings',
  initialState: {
    defaultWeightings: {} as IGitWeighting,
    customWeightings: {} as IGitWeighting,
  },
  reducers: {
    setCustomWeightings: (state, action: PayloadAction<IGitWeighting>) => {
      state.customWeightings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGitWeightings.fulfilled, (state, action) => {
      if (action.payload) {
        state.defaultWeightings = action.payload.defaultWeightings;
        // TODO: temporarily set this until we persist customWeightings
        // state.customWeightings = action.payload.customWeightings;
        state.customWeightings = action.payload.defaultWeightings;
      }
    });
  },
});

export const { setCustomWeightings } = gitWeightingsSlice.actions;

export default gitWeightingsSlice.reducer;
